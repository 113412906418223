import React from 'react';
import styles from '../App.module.css';

const bannerTitle = 'Tech Convite'
const bannerSubTitle = 'Seu Convite Tecnológico'
const bannerSpan = '💻 ♻️ Compartilhe os detalhes do seu evento de maneira personalizada, tecnológica e sustentável com seus convidados!'

export default function Banner() {
  return (
    <div className={styles.banner}>
      <h1 className={styles.bannerTitle}>{bannerTitle}</h1>
      <p className={styles.bannerSubTitle}>{bannerSubTitle}</p>
      <p className={styles.bannerSpan}>{bannerSpan}</p>
    </div>
  )
};