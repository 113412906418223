import React from 'react';
import styles from '../App.module.css';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerContentSection}>
          <h2>Contato</h2>
          <li>contato@techconvite.ga</li>
          <li>Seg - Sex 09:00 às 17:00</li>
          <li>@techconvite</li>
        </div>

        <div className={styles.footerContentSection}>
          <h2>Parcerias</h2>
          <li>Casas de Festas</li>
        </div>

        <div className={styles.footerContentSection}>
          <h2>Formas de Pagamento</h2>
          <li>Cartão</li>  
          <li>Boleto</li>
        </div>

        <div className={styles.footerContentSection}>
          <h2>Politicas</h2>
          <Link to='/faq'><li>Perguntas Frequentes</li></Link>
          <Link to='/politica-de-privacidade'><li>Política de Privacidade e Cookies</li></Link>
          <Link to='/termos-de-uso'><li>Termos de Uso</li></Link>
        </div>
      </div>

      <div className={styles.footerRights}>
        <p>2022 - Todos os direitos reservados.
        CNPJ: 27.769.792/0001-10 
        </p>
      </div>
      
    </div>
  )
};