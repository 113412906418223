import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function NotFound() {
  return (
    <>
    <Header/>
    <div>
      <h2>
      Ops!Página não encontrada
      </h2>
    </div>
    <Footer/>
    </>
  )
};