import React from "react";
import GoogleMapReact from 'google-map-react';

export default function SimpleMap({location}){

  return (
    <div style={{ height: '60vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: ''}}
        defaultCenter={location.center}
        defaultZoom={location.zoom}
      >
      </GoogleMapReact>
    </div>
  );
}