import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import {useCountdown} from '../hooks/useCountdown';
import styles from '../App.module.css';


const ExpiredNotice = () => {
  return (
    <div className={styles.expirednotice}>
      <span>Expired!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className={styles.showcounter}>
        <DateTimeDisplay value={days} type={'Dias'} isDanger={days <= 3} />
        <DateTimeDisplay value={hours} type={'Horas'} isDanger={false} />  
        <DateTimeDisplay value={minutes} type={'Minutos'} isDanger={false} />  
        <DateTimeDisplay value={seconds} type={'Segundos'} isDanger={false} />
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;