import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../App.module.css'

import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Modelos() {
  return (
    <>
      <Header/>
      
        <div className={styles.modelos}>
            <h2>Tech Convites para Bday</h2>
            <p>São dezenas de templates, escolha o seu!</p>

              <div className={styles.modelosGrid}>

                <li>
                  <Link to='/templates/rose'>
                    <img className={styles.modelosGridImg} src='https://cdn.pixabay.com/photo/2018/07/04/13/37/background-3516145_960_720.jpg' alt='Tech Convite Colors'></img>
                    <p>Tech Convite Rose</p> 
                  </Link>
                </li>

                <li>
                  <Link to='/templates/purple'>
                    <img className={styles.modelosGridImg} src='https://cdn.pixabay.com/photo/2015/06/18/08/43/block-813477_960_720.jpg' alt='Tech Convite Colors'></img>
                    <p>Tech Convite Purple</p> 
                  </Link>
                </li>

                <li>
                  <Link to='/templates/autumn'>
                    <img className={styles.modelosGridImg} src='https://cdn.pixabay.com/photo/2013/02/20/11/30/autumn-83761_960_720.jpg' alt='Tech Convite Colors'></img>
                    <p>Tech Convite Autumn</p> 
                  </Link>
                </li>

                <li>
                  <Link to='/templates/green'>
                    <img className={styles.modelosGridImg} src='https://cdn.pixabay.com/photo/2016/07/05/16/53/leaves-1498985__340.jpg' alt='Tech Convite Colors'></img>
                    <p>Tech Convite Green</p> 
                  </Link>
                </li>

                <li>
                  <Link to='/templates/woods'>
                    <img className={styles.modelosGridImg} src='https://cdn.pixabay.com/photo/2015/01/07/16/37/wood-591631_960_720.jpg' alt='Tech Convite Woods'></img>
                    <p>Tech Convite Woods</p> 
                  </Link>
                </li>

                 <li>
                  <Link to='/templates/blue'>
                    <img className={styles.modelosGridImg} src='https://cdn.pixabay.com/photo/2021/01/14/19/30/water-5917708_960_720.jpg' alt='Tech Convite Woods'></img>
                    <p>Tech Convite Blue</p> 
                  </Link>
                </li>

                <li>
                  <Link to='/templates/music'>
                    <img className={styles.modelosGridImg} src='https://cdn.pixabay.com/photo/2016/04/13/15/03/music-sheet-1326999_960_720.jpg' alt='Tech Convite Music'></img>
                    <p>Tech Convite Music</p> 
                  </Link>
                </li>
                
              </div>
        </div>
        
      <Footer/>
    </>
  )
}