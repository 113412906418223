import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function Suporte() {
  return (
    <div>

        <Header/>
         <h2>Canais de Suporte</h2>
         <li>contato@techconvite.ga</li>
         <li>@techconvite no Instagram</li>
        <Footer/>
    </div>
  )
};