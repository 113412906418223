import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from '../App.module.css';

function Form () {
  return (
    <>
    <Header/>
                <li>
                  <button className={styles.button}><a href='https://forms.gle/BBEZLsacxfT8sUC3A'>Ver</a>
                  </button>
                </li>


    <Footer />

    </>
  )

}


export default Form;



//import { googleFormsToJson } from 'react-google-forms-hooks'
// can use both full and shortened form url
//const result = googleFormsToJson(
//  'https://forms.gle/BBEZLsacxfT8sUC3A'
//);
//export default result;