import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../App.module.css';

import Header from '../components/Header';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

const buttonUrl = 'https://sun.eduzz.com/1626205'


export default function Home() {
  return (
    <>
    <Header/>
    <Banner/>

      <div className={styles.homepage}>
        <div className={styles.homepageTitle}>
          <i class="fa-solid fa-ban"></i>
          <p>ATENÇÃO! Não é um "simples arquivo" que você encaminha, é um <b>convite tecnológico</b> TO-TAL-MEN-TE interativo!</p>
        </div>

        <div className={styles.homepageBenef}>
              <li>
                <i class="fa-solid fa-hourglass-end"></i>
                Com uma super contagem regressiva para o grande dia do seu evento
              </li>
              <li>
                <i class="fa-solid fa-map-location-dot"></i>
                Informe o local com coordernadas GPS para facilitar a vida dos seus convidados
              </li>
              <li>
                <i class="fa-solid fa-calendar-days"></i>
                <p>Compartilhe facilmente a data e a hora do seu evento</p>
              </li> 
              <li>
                <i class="fa-brands fa-whatsapp"></i>
                <p>Com link para confirmação de presença pelo WhatsApp</p>
              </li> 
              <li>
                <i class="fa-solid fa-mobile-screen-button"></i>
                <p>Não precisa baixar nenhum aplicativo, seu Tech Convite é acessado com apenas um clique</p>
              </li> 
              <li>
                <i class="fa-solid fa-earth-americas"></i>
                Tech Convite disponível 24 horas por dia, 7 dias por semana
              </li>   
              <li>
                <i class="fa-solid fa-palette"></i>
                <p>Várias opções templates para você escolher</p>
              </li>
              <li>
                <i class="fa-solid fa-recycle"></i>
                <p>Preserve a natureza</p>
              </li>
              <li>
                <i class="fa-solid fa-hand-holding-dollar"></i>
                Além de preservar, você economiza com impressões!
              </li> 
              <li>
              <i class="fa-solid fa-qrcode"></i>
                QR Code Exclusivo do seu Evento para você compartilhar
              </li>   
             
              {/* <li>Compartilhe nas redes sociais: opte pode incluir senha</li> */}
              {/* <li>Desafio para mostrar os dados: você é meu amigo mesmo</li> */}
            
        </div>

        <div className={styles.homepageBonus}>
            <h2>Bônus</h2>
            <div className={styles.homepageBonusList}>
            <li>
              <i class="fa-solid fa-print"></i>
              <h3>Convite para Download e Impressão</h3>
              <p>Não recomendamos a impressão por questões de sustentabilidade, 
                mas também sabemos que existe aquele convidado especial avesso à tecnologia, então que tal imprimir algumas cópias? 
              </p>
            </li>
            
            <li>
              <i class="fa-solid fa-qrcode"></i>
              <h3>QR Code Exclusivo para a seu Tech Convite</h3> 
              <p>Mais uma forma incrível, tecnológica e sustentável para compartilhar com seus convidados.
              </p>
            </li>
            
            <li>
              <i class="fa-solid fa-gears"></i>
              <h3>Altere a data e local do evento facilmente</h3>
              <p>Sabemos que imprevistos podem acontecer, então se precisar alterar datas, locais ou outra informação relevante, é só solicitar a alteração sem custo adicional nenhum.
              </p>
            </li>
        </div>
        </div>

        <div className={styles.homepageOrientacoes}>
          <div className={styles.homepageComoFunciona}>
                <h2>Como funciona?</h2>
                <li>
                  <i class="fa-solid fa-circle-check"></i>
                  Faça seu pedido
                </li>
                <li>
                  <i class="fa-solid fa-circle-check"></i>
                  Após a confirmação do pagamento, você receberá um e-mail para informar os dados do seu evento
                </li>
                <li>
                  <i class="fa-solid fa-circle-check"></i>
                  Escolha o seu template preferido
                </li>
                <li>
                  <i class="fa-solid fa-circle-check"></i>
                  Aguarde! Em até 24 horas úteis seu Tech Convite estará ativo
                </li>
          </div>
        </div>
        
        <div className={styles.homepageCompra}>
          <h2>Quanto custa?</h2>
            <h4>De 89,00</h4>
            <p>por apenas</p>
            <h3>59,00</h3>
            <button>
            <a href={buttonUrl}>Solicite Agora</a>
              </button>
            <p>*Seu Tech Convite ficará ativo por 12 meses, 7 dias por semana, 24 horas por dia.</p>
        </div>

        <div className={styles.homepageModelos}>
            <h2>São Dezenas de Templates Incríveis</h2>
            <p>Confira nossos Templates Exclusivos e solicite o seu Tech Convite</p>
            <button>
              <Link to='/templates'>Veja os Modelos</Link></button>
        </div>    

        <div className={styles.homepageFaq}>
            <h2 id={styles.faq}>FAQ</h2>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                E quanto ao meu Convite para Impressão Bônus? Como receberei?
              </li>
              <p>Seu bônus <b>Convite para Impressão</b> ficará disponivel para download no seu Tech Convite.</p>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                Quanto tempo demora a ativação do meu Tech Convite?
              </li>
              <p>A ativação ocorre em até 24 horas úteis após você informar todos os dados. Dúvidas, estamos a dispoição por e-mail e/ou Instagram.</p>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                Por quanto tempo meu Tech Convite fica ativo?
              </li>
              <p>Seu Tech Convite ficará ativo por 12 meses após a data da compra.</p>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                E o bônus para impressão? Posso escolher o modelo?
              </li>
              <p>O convite bônus acompanha o template do Tech Convite, portanto não é possível escolher. Ao escolher o modelo do Tech Convite, 
                você automaticamente escolhe o Bônus.</p>
        </div>

        <div className={styles.homepageCompra}>
          <h2>Faça seu Pedido</h2>
            <h4>De 89,00</h4>
            <p>por apenas</p>
            <h3>59,00</h3>
            <button>
            <a href={buttonUrl}>Solicite Agora</a>
            </button>
            <p>*Seu Tech Convite ficará ativo por 12 meses, 7 dias por semana, 24 horas por dia.</p>
        </div>

      </div>
    <Footer/>
    </>
  )
}

