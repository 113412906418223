import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function ThankYouCard() {
  return (
    <>
    <Header/>
    <div>
       Thank you page
    </div>
    <Footer/>
    </>
  )
};