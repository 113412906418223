import React from 'react';
import FooterFinal from '../components/FooterFinal';
import CountdownTimer from '../components/CountdownTimer';
import SimpleMap from '../components/Maps';
import QRCodeCanvas from '../components/QRCode';
import styles from '../templates/colors/purple.module.css';

/*INSTRUÇÕES */
/*Nome do Evento*/
const event = 'Bday Fer Borges'
const phrase = 'Você está intimado à comparecer ao meu aniversário! Vem aí:'

/* Data do evento */
const defaultEndDate = new Date("Nov 26, 2022 08:00:00");

/* Data e hora*/
const date = '26/11/2022'
const hour = '12:00 hrs'

/* Endereço */
const street ='Rua João Boscardin Junior';
const number = 'S/N';
const city = 'Apucarana';
const uf = 'PR';

/*Coordenadas Mapa */
const location = {
  center: {
    lat: -19.9026615,
    lng: -44.1041402,
  },
  zoom: 11
};

/*Infos do evento*/
const info = 'Presença indispensável'


/*Convite para donwload */
const confirmationLink = 'https://wa.me/+5543996551336?text=Oi+Fer%21+Irei+ao+seu+anivers%C3%A1rio'

const inviteFile = ''

/*QR Code */
const url = 'https://techconvite.ga/bday/fer-borges'


export default function PostFerBorges() {
  return (
      <>
      
      <div className={styles.body}>
          <div className={styles.header}>
            <h2>{phrase}</h2>
            <h1>{event}</h1>
            <h2>Faltam exatamente:</h2>
          </div>
      </div>
        
      <div className={styles.content}>
        
          <div className={styles.contentCountdown}>
            <div className={styles.countdown}>
                <CountdownTimer targetDate={defaultEndDate}/> 
            </div>
          </div>      
      </div>

          <div className={styles.contentEventData}>

                <div className={styles.eventData}>
                  <i class="fa-solid fa-calendar-days"></i>
                    <p>Espero você em</p>
                    <p>{date} às {hour}</p>
                  {/* <h2>Já coloca na sua agenda!</h2> */}
                </div>

                <div className={styles.eventData}>
                  <i class="fa-solid fa-map-location-dot"></i>
                  {/* <h2>Onde vai ser?</h2> */}
                  <p>{street}, nº {number}.</p>
                  <p>{city} - {uf}</p>
                </div>
          </div>

          <div className={styles.contentEventData}>

            <div className={styles.eventData}>
              <i class="fa-solid fa-triangle-exclamation"></i>
              <p>{info}</p>
            </div>

          </div>


          <div className={styles.contentEventMap}>

            
              <div className={styles.googleMaps}>
                <SimpleMap 
                  location={location}
                />
              </div>
          </div>
          
          <div className={styles.contentEventData}>

            <div className={styles.eventData}>
              <button className={styles.button}>
                <a href={confirmationLink}> 
                    <i class="fa-brands fa-whatsapp"></i> 
                </a>
              </button>
                <p>Confirme sua presença</p>
            
            </div>

            <div className={styles.eventData}>
                <button className={styles.button}>
                  <a href={inviteFile}>
                      <i class="fa-solid fa-circle-down"></i>
                      </a>
                </button>
                <p>Salve o Convite</p>
            </div>
      
          </div>

          <div className={styles.contentEventData}> 
            <div className={styles.eventData}>
                  <p>QR Code Exclusivo do Evento</p>
                  <QRCodeCanvas
                  url={url}
                  />                
            </div>
         
          </div>

      <FooterFinal/>
    </>
)};