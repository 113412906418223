import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function ThankYouBoleto() {
  return (
    <>
      <Header/>
      <div>
        Thank you page Boleto
      </div>
      <Footer/>
    </>
  )
};