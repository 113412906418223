import styles from '../App.module.css';
import React from 'react';
import { Link } from 'react-router-dom';

const homeUrl ='/'
const linkUrl = '/templates'
const buttonUrl = 'https://sun.eduzz.com/1626205'


export default function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.headerTitle}>
          <Link to={homeUrl}>
            <img src='../logo.png' alt='tech convite' className={styles.logo}>
            </img>
          </Link>
      </div>

      <div className={styles.headerMenu}>
          <li>
            <Link to={linkUrl}>Modelos</Link>
          </li>
      </div>
      
      <div className={styles.headerButton}>
        <button>
          <a href={buttonUrl}>Comprar</a>
          </button>
      </div>

      <div className={styles.headerSocialIcons}>
        <a href='https://www.instagram.com/techconvite/'target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-instagram"></i>
        </a>
      </div>
      
    </div>
  );
}