import React from 'react';
import styles from '../App.module.css';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function Faq() {
  return (
    <div>

        <Header/>
           <div className={styles.homepageFaq}>
            <h2 id={styles.faq}>FAQ</h2>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                E quanto ao meu Convite para Impressão Bônus? Como receberei?
              </li>
              <p>Seu bônus <b>Convite para Impressão</b> ficará disponivel para download no seu Tech Convite.</p>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                Quanto tempo demora a ativação do meu Tech Convite?
              </li>
              <p>A ativação ocorre em até 24 horas úteis após você informar todos os dados. Dúvidas, estamos a dispoição por e-mail e/ou Instagram.</p>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                Por quanto tempo meu Tech Convite fica ativo?
              </li>
              <p>Seu Tech Convite ficará ativo por 12 meses após a data da compra.</p>
              <li>
                <i class="fa-solid fa-circle-question"></i>
                E o bônus para impressão? Posso escolher o modelo?
              </li>
              <p>O convite bônus acompanha o template do Tech Convite, portanto não é possível escolher. Ao escolher o modelo do Tech Convite, 
                você automaticamente escolhe o Bônus.</p>
        </div>
        <Footer/>
    </div>
  )
};