import styles from '../App.module.css';
import React from 'react';
import { Link } from 'react-router-dom';

const homeUrl ='/'
const buttonUrl = 'https://sun.eduzz.com/1626205'

export default function HeaderTemplate() {
  return (
    <div className={styles.headerTemplate}>
      <div className={styles.headerTitleTemplate}>
        <Link to={homeUrl}>
          <img src='../logo.png' alt='tech convite' className={styles.logoTemplate}>
          </img>
        </Link>
      </div>
      <div className={styles.headerTemplateMenu}>
          <Link to='/templates'>Modelos</Link>
      </div>
      <div className={styles.headerButtonTemplate}>
        <button>
         <a href={buttonUrl}>Comprar</a>
        </button>
      </div>
      <div className={styles.headerSocialIcons}>
        <a href='https://www.instagram.com/techconvite/'target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-instagram"></i>
        </a>
      </div>
    </div>
  );
}