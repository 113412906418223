import {useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";


const QrCode = ({url}) => {
  const qrRef = useRef();

  const qrCodeEncoder = (e) => {
    url(e.target.value);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={url}
      size={90}
      bgColor={"#ffffff"}
      level={"H"}
    />
  );

  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = 'convite.png';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  
  return (
    <div className="qrcode">
      <div ref={qrRef}>{qrcode}
      </div>
        {<form 
            onClick={qrCodeEncoder}
            onSubmit={downloadQRCode} >
              
          {/* { <button type="submit">
            <i class="fa-solid fa-circle-down"></i>
            </button> } */}
        </form> }
      </div>
  
  );
};

export default QrCode;