import { Routes, Route } from 'react-router-dom';
import './App.module.css';

import Modelos from './pages/Modelos';
import Home from './pages/Homepage';
import ThankYouCard from './pages/ThankYouCard';
import ThankYouBoleto from './pages/ThankYouBoleto';
import Form from './pages/Form';
import NotFound from './pages/NotFound';

/*Templates */
import TemplateColorRose from './templates/Rose';
import TemplateGreen from './templates/Green';
import TemplatePurple from './templates/Purple';
import TemplateWoods from './templates/Woods';
import TemplateMusic from './templates/Music';
import TemplateBlue from './templates/Blue';
import TemplateAutumn from './templates/Autumn';


//Posts
import PostRenataChaves from './posts/PostRenataChaves';
import Faq from './pages/Faq';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import TermosdeUso from './pages/TermosdeUso';
import Suporte from './pages/Suporte';
import PostFerBorges from './posts/PostFerBorges';


export default function App() {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/templates" element={<Modelos />} />
        <Route path="*" element={<NotFound />} />
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/politica-de-privacidade' element={<PoliticaPrivacidade/>}/>
        <Route path='/termos-de-uso' element={<TermosdeUso/>}/>
        <Route path='/canais-de-suporte' element={<Suporte/>}/>
        <Route path='/obrigado' element ={<ThankYouCard/>}/>
        <Route path='/aguardando-boleto' element ={<ThankYouBoleto/>}/>
        <Route path='/personalize-seu-convite' element={<Form/>}/>

        {/* Abaixo, templates */}
        
        <Route path="/templates/rose" element={<TemplateColorRose/>}/>
        <Route path="/templates/green" element={<TemplateGreen/>}/>
        <Route path="templates/purple" element={<TemplatePurple/>}/>
        <Route path="templates/woods" element={<TemplateWoods/>}/>
        <Route path="templates/music" element={<TemplateMusic/>}/>
        <Route path="templates/blue" element={<TemplateBlue/>}/>
        <Route path="templates/autumn" element={<TemplateAutumn/>}/>
  

        {/* Abaixo, páginas de projetos ativos para clientes */}

        <Route path='bday/renata-chaves' element={<PostRenataChaves/>}/>
        <Route path='bday/fer-borges' element={<PostFerBorges/>}/>
        

      </Routes>
    </>
  );
}