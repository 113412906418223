import React from 'react';
import styles from '../App.module.css';

function FooterFinal() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        
      <div className={styles.footerContentSection}>
          <h2>Convite Desenvolvido por <a href='https://www.techconvite.ga/'>Tech Convites</a></h2>
          <a href='https://www.techconvite.ga/'><button>Peça já o seu</button></a>
          <li>contato@techconvite.ga</li>
        </div>

      </div>

      <div className={styles.footerRights}>
        <p>2022 - Todos os direitos reservados.
        CNPJ: 27.769.792/0001-10 
        </p>
      </div>
      
    </div>
  )
}

export default FooterFinal;